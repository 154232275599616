body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #121212;
}

.banner-container {
  position: relative;
  width: 100%;
  height: 50vw;
  overflow: hidden;
}

.banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease;
}

/* Image 2 slide-in animation */
@keyframes slideImage2 {
  from {
    right: -100%; /* Start off the screen */
  }
  to {
    right: 0; /* Ends when the right side aligns with the window's right side */
  }
}

/* Image 3 slide-in animation */
@keyframes slideImage3 {
  from {
    left: -100%; /* Start off the screen */
  }
  to {
    left: 10vw; /* Ends with 10px gap from the left side */
  }
}

/* Image 4 slide-in animation */
@keyframes slideImage4 {
  from {
    left: -100%; /* Start off the screen */
  }
  to {
    left: 10vw; /* Ends with 10px gap from the left side */
  }
}

.image2 {
  position: absolute;
  top: 0;
  max-width: 50%;
  object-fit: cover;
  opacity: 1; /* Initially hidden */
  right: 0;
  z-index: 100;
}

.image3 {
  position: absolute;
  top: 5vw;
  max-width: 40%;
  object-fit: cover;
  opacity: 1; /* Initially hidden */
  left: 10vw;
}

.image4 {
  position: absolute;
  top: 15vw;
  max-width: 10%;
  object-fit: cover;
  opacity: 1; /* Initially hidden */
  left: 10vw;
}

/* Initially, image2 will be animated from right to left */
.wimage2 {
  animation: slideImage2 2s forwards;
  animation-delay: 1s; /* Delay to make sure image1 has loaded */
}

/* Initially, image3 will be animated from right to left with a gap */
.wimage3 {
  animation: slideImage3 2s forwards;
  animation-delay: 2s; /* Delay for after image2 finishes */
}

/* Initially, image4 will be animated from right to left with a gap */
.wimage4 {
  animation: slideImage4 2s forwards;
  animation-delay: 3s; /* Delay for after image2 finishes */
}

#tsparticles{
  height: 30vw;
  border:2px solid gold
}

.tsparticles{
  height: 30vw;
  border:2px solid gold
}

/* @font-face {
  font-family: 'UN-Abhaya'; 
  src: local('###'), url('./fonts/UN-Abhaya.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'UN-Abhaya-bold-2019'; 
  src: local('###'), url('./fonts/UN-Abhaya-bold-2019.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'UN-Malithi'; 
  src: local('###'), url('./fonts/UN-Malithi.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'UN-Alakamanda'; 
  src: local('###'), url('./fonts/UN-Alakamanda.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'UN-Bindumathi'; 
  src: local('###'), url('./fonts/UN-Bindumathi.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'UN-Imanee'; 
  src: local('###'), url('./fonts/UN-Imanee.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'UN-Ganganee'; 
  src: local('###'), url('./fonts/UN-Ganganee.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'UN-Agni'; 
  src: local('###'), url('./fonts/UN-Agni.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'UN-Derana'; 
  src: local('###'), url('./fonts/UN-Derana.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'UN-Samantha'; 
  src: local('###'), url('./fonts/UN-Samantha.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'UN-Sandhyanee'; 
  src: local('###'), url('./fonts/UN-Sandhyanee.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'UN-Basuru'; 
  src: local('###'), url('./fonts/UN-Basuru.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'UN-Gurulugomi'; 
  src: local('###'), url('./fonts/UN-Gurulugomi.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'UN-Arundathee'; 
  src: local('###'), url('./fonts/UN-Arundathee.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'UN-Isiwara'; 
  src: local('###'), url('./fonts/UN-Isiwara.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'UN-Rashmi'; 
  src: local('###'), url('./fonts/UN-Rashmi.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'UN-Davasa'; 
  src: local('###'), url('./fonts/UN-Davasa.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'UN-Disapamok'; 
  src: local('###'), url('./fonts/UN-Disapamok.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'UN-Indeewaree'; 
  src: local('###'), url('./fonts/UN-Indeewaree.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'UN-Dharanee'; 
  src: local('###'), url('./fonts/UN-Dharanee.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'UN-Rajantha'; 
  src: local('###'), url('./fonts/UN-Rajantha.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'UN-Gemunu'; 
  src: local('###'), url('./fonts/UN-Gemunu.ttf') format('truetype');
  font-weight: normal;
} */

@font-face {
  font-family: 'UN-Derana'; 
  src: local('###'), url('./fonts/UN-Derana.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'UN-Gurulugomi'; 
  src: local('###'), url('./fonts/UN-Gurulugomi.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'UN-Arundathee'; 
  src: local('###'), url('./fonts/UN-Arundathee.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'UN-Abhaya-bold-2019'; 
  src: local('###'), url('./fonts/UN-Abhaya-bold-2019.ttf') format('truetype');
  font-weight: normal;
}

.sc-h{
  color: #fac928;
  font-family: 'UN-Arundathee';
  font-size: min(40px, 6vw);
  
}

.sc-p{
  color: #e8fa64;
  font-family: 'UN-Gurulugomi';
  font-size: min(30px, 5vw);
}

.col-xs-1{
  display: none;
}

.col-xs-11{
color: #e6f09c;
font-family: 'UN-Arundathee';
font-size: min(30px, 5vw);
}

.suthra-container{
  display: flex;           /* Enable flexbox */
  justify-content: center; /* Center child elements horizontally */
  align-items: center;     /* Center child elements vertically (optional) */

}

.all-suthra{
  max-width: 800px;
}

.orig-link{
  display: none;
}

.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
  margin: 0.5em
}

.resp-sharing-button {
  border-radius: 5px;
  transition: 25ms ease-out;
  padding: 0.5em 0.75em;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif
}

.resp-sharing-button__icon svg {
  width: 1em;
  height: 1em;
  margin-right: 0.4em;
  vertical-align: top
}

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
  stroke: #fff;
  fill: none
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none
}

.resp-sharing-button--facebook {
  background-color: #010b4a;
  border-color: #010730;
}

.resp-sharing-button--facebook:hover,
.resp-sharing-button--facebook:active {
  background-color: #2d4373;
  border-color: #2d4373;
}

.resp-sharing-button--whatsapp {
  background-color: #0c4502;
  border-color: #083001;
}

.resp-sharing-button--whatsapp:hover,
.resp-sharing-button--whatsapp:active {
  background-color: #1DA851;
  border-color: #1DA851;
}

.txt-seo{
  font-family: 'UN-Arundathee'; color: #ede609; text-align: center; font-size: 15px;display: none;
}

